<template>
  <div v-frag>
    <v-card :loading='loadingGuides' class='mx-8 elevation-4 pa-8'>
      <div v-if='!loadingGuides'>
        <v-row class='d-flex align-center justify-space-between'>
          <h1 class='pl-0'>Your Voter Guide</h1>
          <v-btn
            :disabled='!votersGuides.length'
            :loading='loadingExport'
            color='primary'
            @click='exportPDF'
          >
            Export PDF
            <v-icon dark right>
              mdi-upload
            </v-icon>
          </v-btn>
        </v-row>
        <!--        <h3 class='pl-4 mt-8'>References:</h3>-->
        <v-row class='pl-6 mb-10 mt-8'>
          <div class='d-flex flex-row align-center justify-center mr-4'>
            <div class='green-circle'></div>
            <p class='ml-4 mb-0'>For</p>
          </div>
          <div class='d-flex flex-row align-center justify-center mr-4'>
            <div class='grey-circle'></div>
            <p class='ml-4 mb-0'>Neutral</p>
          </div>
          <div class='d-flex flex-row align-center justify-center'>
            <div class='red-circle'></div>
            <p class='ml-4 mb-0'>Against</p>
          </div>
        </v-row>

        <div v-if='votersGuides.length' v-frag>
          <v-card
            v-for='guide in votersGuides'
            :key='guide.id'
            class='mt-4 elevation-4 pa-8'
          >

            <div v-if="guide.voterType === 'Person'">
              <v-row class='d-flex flex-row justify-center align-center px-6 mb-0'>
                <v-card-title class='ml-0 pl-0 d-flex flex-column'>
                  Candidate
                </v-card-title>
              </v-row>
              <v-row class='d-flex flex-row justify-space-between align-center px-3 mb-0'>
                <v-card-title class='pl-0 d-flex flex-column ml-0 align-start'>
                  <span>
                    Seat: {{ guide.type }}
                  </span>
                  <template v-if='guide.isParty'>
                    <span v-if="guide.voterType === 'Person'">
                      Party: {{ guide.party }}
                    </span>
                  </template>
                </v-card-title>
                <v-btn :color='guide.color' justify-self='end' large width='200px'>
                  {{ guide.position }}
                </v-btn>
              </v-row>
              <v-row class='d-flex flex-row justify-start align-center pl-3 mb-0'>
                <p>{{ guide.zone }}</p>
              </v-row>
              <v-row class='d-flex flex-row justify-center align-center pr-10 mb-6'>
                <h3>{{ guide.title }}{{ guide.name }}</h3>
              </v-row>
              <h5 v-if='guide.viewpoint'>Rationale:</h5>
              <p v-if='guide.viewpoint'>{{ guide.viewpoint }}</p>
            </div>

            <div v-if="guide.voterType === 'Legislation'">
              <v-row class='d-flex flex-row justify-center align-center px-6 mb-0'>
                <v-card-title class='ml-0 pl-0 d-flex flex-column'>
                  Legislation
                </v-card-title>
              </v-row>
              <v-row class='d-flex flex-row justify-space-between align-center px-3 mb-0'>
                <v-card-title class='pl-0 d-flex flex-column ml-0 align-start'>
                  <span>
                    Location: {{ guide.type }}
                  </span>
                  <template v-if='guide.isParty'>
                    <span v-if="guide.voterType === 'Person'">
                      Party: {{ guide.party }}
                    </span>
                  </template>
                </v-card-title>
                <v-btn :color='guide.color' justify-self='end' large width='200px'>
                  {{ guide.position }}
                </v-btn>
              </v-row>
              <v-row class='d-flex flex-row justify-start align-center pl-3 mb-0'>
                <p>{{ guide.zone }}</p>
              </v-row>
              <v-row class='d-flex flex-row justify-center align-center pr-10 mb-6'>
                <h3>{{ guide.title }}{{ guide.name }}</h3>
              </v-row>
              <h5>Description:</h5>
              <p>{{ guide.description }}</p>
              <h5 v-if='guide.viewpoint'>Rationale:</h5>
              <p v-if='guide.viewpoint'>{{ guide.viewpoint }}</p>
            </div>

          </v-card>
        </div>
        <v-card-title v-else class='text-center d-block font-weight-regular'>
          {{ noVoterGuideText }}
        </v-card-title>
      </div>
    </v-card>
  </div>
</template>
<script>
import employeeService from '../../services/employee/index'
import {mapGetters} from 'vuex'
import {HTTP} from '../../../http-common'
import helper from '@/services'

export default {
  data: () => ({
    votersGuides: [],
    loadingExport: false,
    loadingGuides: true,
    noVoterGuideText: null
  }),
  created() {
    this.initialize()
  },
  computed: mapGetters(['user', 'election', 'voterGuideUserParams']),
  methods: {
    async initialize() {
      await this.getVotersGuides()
      if (!this.votersGuides.length) await this.getVotersGuideContent()
      this.loadingGuides = false
    },
    async exportPDF() {
      this.loadingExport = true
      location.href = HTTP.defaults.baseURL + `employees/${this.$route.params.employeeId || this.voterGuideUserParams.employeeId}/voters-guides-export-pdf/${this.election.id || this.voterGuideUserParams.electionId}`
      this.loadingExport = false
    },
    async getVotersGuides() {
      this.votersGuides = []
      try {
        const allGuides = await employeeService.getVoterGuidesByEmployeeId(this.$route.params.employeeId || this.voterGuideUserParams.employeeId, this.election.id || this.voterGuideUserParams.electionId)
        allGuides.forEach((guide) => {
          switch (guide.position) {
            case 'For':
              guide.color = 'success'
              break
            case 'Against':
              guide.color = 'error'
              break
            case 'Neutral':
              guide.color = 'grey'
              break
          }
        })
        this.votersGuides = allGuides
      } catch (err) {
        console.log(err)
      }
    },
    async getVotersGuideContent() {
      this.noVoterGuideText = null
      try {
        const response = await HTTP.get(
          'get-voters-guide-content',
          {headers: helper.setHeaders()}
        )
        this.noVoterGuideText = response.data[0].content[0].content
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang='scss'>
.green-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #53af50;
}

.grey-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #9e9e9e;
}

.red-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #b73628;
}

.pointer {
  pointer-events: none !important;
}
</style>
